
<template>
    <div class="layout-login">
        <Row class="layout-login-content" type="flex" align="middle">
            <div class="login-logo"></div>
            <Col class="right" span="24">
            <div class="login-title m-t-30" v-if="!isWeChat">欢迎登录</div>
            <p class="welcome" v-if="!isWeChat">WELCOME</p>
            <div class="login-close" v-show="isWeChat"><Icon type="md-close" size="24" class="c-h close" @click="isWeChat = !isWeChat" /></div>
            <Form class="login-form m-r-30" ref="accountForm" :model="accountForm" :label-width="30" :rules="accountFormRule" v-if="!isWeChat">
                <Form-item prop="name" class="m-b-20">
                    <Input class="m-t-10" type="text" v-model="accountForm.name" @on-enter="login" size="large" placeholder="用户名" />
                </Form-item>
                <Form-item prop="pwd" class="m-b-20">
                    <Input type="password" v-model="accountForm.pwd" @on-enter="login" size="large" placeholder="密码" />
                </Form-item>
                <Form-item class="m-b-10">
                    <Button type="primary" style="border-radius:20px" long @click="login" size="large">登 录</Button>
                    <div class="m-t-20 text-c">没有账号，立即<span class="login-register c-h" @click="register">注册</span></div>
                </Form-item>
                <Form-item class="text-c">
                    <Divider>其他方式登录</Divider>
                    <Icon class="c-h weChat-icon" custom="iconfont icon-weixin1" :size="30" @click.native="getWxState" />
                </Form-item>
            </Form>
            <div class="w-100 text-c" v-show="isWeChat">
                <div id="wxLogin">加载失败，请重新加载！</div>
            </div>
            </Col>
        </Row>
        <div class="copyright">{{page.copyright}}</div>
        
    </div>
</template>
<script>
import md5 from "md5"
import { mapState } from "vuex"
import { config } from "@/config"
import { util } from "@/libs"
import "@/libs/wxLogin"

export default {
    data() {
        return {
            isWeChat: false,
            accountForm: {},
            accountFormRule: {
                regAccount: [{ required: true, trigger: "change", pattern: config.pattern.regName, message: "请输入登陆用户名" }],
                pass: [{ required: true, pattern: config.pattern.pass, trigger: "change", message: '请输入6-22位字母或数字组合' }],
            },
        };
    },
    computed: {
        ...mapState({
            page: state => state.ivew.config,
            user: state => state.user
        })
    },
    methods: {

        getWxState() {
            this.isWeChat = !this.isWeChat
            // var obj = new WxLogin({
            //             self_redirect: false,
            //             id: 'wxLogin',
            //             appid: 'wxcc2bfb36f5d889b8',
            //             scope: 'snsapi_login',
            //             redirect_uri: encodeURIComponent('http://web.xingyaedu.cn/?redirect_uri=http://yx.xingyaedu.cn/'),
            //             state: 'a4k049erjr2twubx',
            //         })

            // window.open(
            //     `https://open.weixin.qq.com/connect/qrconnect?appid=wxcc2bfb36f5d889b8&redirect_uri=http://web.xingyaedu.cn&response_type=code&scope=snsapi_login&state=a4k049erjr2twubx#wechat_redirect`,
            //     '',
            //     "width=900,height=600,top=100,left=500"
            //     )

            this.$store.dispatch('user/getWxState').then(res => {
                if(res.code == "HA0200") {
                    document.getElementById('wxLogin').innerHTML = ""
                    var obj = new WxLogin({
                        self_redirect: false,
                        id: 'wxLogin',
                        appid: 'wxcc2bfb36f5d889b8',
                        scope: 'snsapi_login',
                        redirect_uri: encodeURIComponent('http://web.xingyaedu.cn/?redirect_uri=http://yx.xingyaedu.cn'),
                        state: res.data,
                    })

                } else {
                    document.getElementById('wxLogin').innerHTML = "加载失败，请重新加载！"
                }
                
            })
        },
        // getWxLogin() {
        //     // http://web.xingyaedu.cn/?redirect_uri=http://yx.xingyaedu.cn/&code=061nKz1w3N0HR03CR40w3tlIez0nKz1V&state=v52ueijvmdrfc01o
        //     this.$store.dispatch('user/wxLogin', { code: '061nKz1w3N0HR03CR40w3tlIez0nKz1V', state: 'v52ueijvmdrfc01o' }).then(res => {
        //         console.log(res)
        //         if (res.code == 200) {
        //         } else {
        //             this.$Message.warning(res.msg || '微信登录错误！')
        //         }
        //     })
        // },
        // 注册
        register() {
            this.$router.push('/register')
        },
        loginSuccess(res) {
            const { routers, user, authCode } = this.$store.state.user
            this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://pan.xingyaedu.cn' }).then(res => {
                if(res.code == 200) {
                    this.$store.dispatch('user/getTokenNetdisk', { authCode: res.data })
                }
            })
            // TODO: 同时获取token，研学的token获取不到
            // this.$store.dispatch('user/getAuthCode', { clientId: '1001', redirectUri: 'http://web.xingyaedu.cn' }).then(res => {
            //     if(res.code == 200) {
            //         this.$store.dispatch('user/getTokenMarket', { authCode: res.data })
            //     }
            // })
            this.$store.dispatch('user/getAuthCode', { clientId: '1004', redirectUri: 'http://yanxue.manage.xingyaedu.cn' }).then(res => {
                if(res.code == 200) {
                    this.$store.dispatch('user/getTokenResearch', { authCode: res.data }).then((res) => {
                        this.$router.addRoutes(routers)
                        const [dataV, system, config] = routers
                        const path = dataV.index || config.index || system.index
                        if(path) {
                            this.$router.push(path)
                        }
                    })
                }
            })
            

        },
        login() {
            this.$refs.accountForm.validate(valid => {
                if (valid) {
                    const { name, pwd } = this.accountForm
                    this.$store.dispatch("user/userLogin", { name, pwd }).then(this.loginSuccess);
                }
            })
        }
    }
};
</script> 
<style lang="less">
.layout-login {
    background: #333;
    background: url("/static/images/login/login_bg.jpg") no-repeat;
    background-size: cover;
    background-position: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
    letter-spacing: 2px;
    .layout-login-content {
        width: 500px;
        height: 500px;
        border-radius: 20px;
        background: #fff;
        position: absolute;
        top: calc(50% - 200px);
        left: calc(50% - 250px);
    }
    .login-logo {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: url('/static/images/login/logo.png') no-repeat center center;
      background-size: cover;
      position: absolute;
      left: calc(50% - 50px);
      top: -50px;
    }
    .login-title {
        font-weight: bolder;
        font-size: 34px;
        text-align: left;
    }
    .login-close {
        text-align: right;
        .close:hover {
            transform: scale(1.3);
        }
    }
    .login-register {
      color: #6464f1;
      &:hover {
        color: #0000ff;
      }
    }
    .right {
        padding: 0 40px;
        .welcome {
            font-size: 16px;
        }
        .login-form {
            margin-top: 40px;
            font-family: "黑体";
            input {
                background: transparent;
                border: transparent;
                border-bottom: 1.5px solid #f2f2f2;
            }
            .ivu-form-item-error-tip {
                color: rgb(182, 88, 88);
            }
            .weChat-icon {
                color: rgb(40, 196, 69)
            }
        }
        #wxLogin {
            width: 100%;
            height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .copyright {
        font-family: "黑体";
        position: absolute;
        width: 100%;
        bottom: 30px;
        font-size: 14px;
        color: rgba(200, 200, 200, 0.7);
        text-align: center;
    }
}
</style>

